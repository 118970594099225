import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import { getMatchupData, getNFLState, getStats, getTeamData } from './apiData';
import './App.css';
import { match } from 'assert';

export interface NFLState {
  week: number,
  year: number,
  season_type: string,
  currentWeek: number
}

export interface PlayerPoints {
  [playerId: string]: number;
}

export interface MatchupData {
  points: number;
  players: string[];
  roster_id: number;
  custom_points: number | null;
  matchup_id: number;
  starters: string[];
  starters_points: number[];
  players_points: PlayerPoints;
}

export interface TeamInfo {
  displayName: string,
  teamName: string,
  avatarPath: string,
  userID: string,
  rosterID: number
}

export interface IceData {
  rosterID: number,
  iceCount: number
}

const App: React.FC = () => {

  const [leagueState, setLeagueState] = useState<NFLState | undefined>();
  const [matchupData, setMatchupData] = useState<MatchupData[] | undefined>();
  const [teamInfo, setTeamInfo] = useState<TeamInfo[] | undefined>();
  const [playersWithStats, setPlayersWithStats] = useState<string[] | undefined>();
  const [iceData, setIceData] = useState<IceData[] | undefined>();
  const [weekOffset, setWeekOffset] = useState<number>(0);
  const [shotguns, setShotguns] = useState<number[]>([]);
  const [weekStarted, setWeekStarted] = useState<boolean>(true);
  const leagueID = "1124852260086493184"
  const iceCube = "🧊"
  const beer = "🍺"
  const weekOffsetKey = "iceWatch-weekOffset";

  useEffect(() => {
    const fetchNFLState = async () => {

      const weekOffsetRaw = localStorage.getItem(weekOffsetKey);

      const weekOffsetLocal = weekOffsetRaw !== null ? parseInt(weekOffsetRaw) : 0;

      const state = await getNFLState(weekOffsetLocal);
      //4039 = kupp
      setLeagueState(state)
      setWeekOffset(weekOffsetLocal)
    };

    const fetchTeamInfo = async () => {
      const teams = await getTeamData(leagueID);
      setTeamInfo(teams)
    };

    fetchNFLState();
    fetchTeamInfo();
  }, [weekOffset]);

  useEffect(() => {
    const fetchMatchupData = async () => {

      if (!leagueState)
      { 
        return;
      }
      const matchups = await getMatchupData(leagueID, leagueState.currentWeek - weekOffset);
      setMatchupData(matchups)
    };

    const fetchStats = async () => {

      if (!leagueState)
      { 
        return;
      }

      if (weekOffset === 0 && leagueState.week === leagueState.currentWeek)
      {
        // console.log("week:", leagueState.week, "currentWeek:", leagueState.currentWeek)
        const stats = await getStats(leagueState.season_type, leagueState.year, leagueState.currentWeek - weekOffset);
        setPlayersWithStats(stats)
      }
    };

    fetchMatchupData();
    fetchStats();
  }, [leagueState, weekOffset]); // Empty dependency array ensures this runs once on component mount

  useEffect(() => {
    
    const iceData = checkForIces();

    setIceData(iceData);

  }, [matchupData, playersWithStats, weekOffset]); // Empty dependency array ensures this runs once on component mount

  const checkForIces = () => {

    let ices: IceData[] = [];

    if (!matchupData)
    {
      return;
    }

    let currentMin = 99999;

    let gamesFound = false;

    matchupData.forEach(m => {

      const rosterID = m.roster_id;

      if (m.points < currentMin)
      {
        currentMin = m.points
      }

      const zeroPoints = m.starters_points.filter(f => f === 0);

      if (zeroPoints.length !== m.starters_points.length)
      {
        gamesFound = true;
      }

      let iceCount = 0;
      m.starters_points.forEach((sp, index) => {
        if (sp < 0)
        {
          iceCount++;
        }

        if (sp === 0)
        {
          const playerID = m.starters[index];

          //player has played
          if (playersWithStats?.includes(playerID) || weekOffset !== 0) {
              iceCount++;
          }
        }
      })

      const iceLocal: IceData = {rosterID, iceCount};
      ices.push(iceLocal)
    });

    const shotguns = matchupData.filter(mm => mm.points === currentMin).map(x => x.roster_id);

    setShotguns(shotguns);

    setWeekStarted(gamesFound);

    return ices;
  }

  const DisplayIces: React.FC = () => {
    const filteredIceData = iceData?.filter(i => i.iceCount > 0 || shotguns.includes(i.rosterID));
    
    const totalIces = filteredIceData?.reduce((sum, x) => sum + x.iceCount, 0);



    if (filteredIceData?.length === 0 || totalIces === undefined)
    {
      return (<div>{`No ices :/`}</div>)
    }

    const totalIcesText = totalIces === 0 ? "No Ices :/" : `Ice Count: ${totalIces}`

    if (!weekStarted)
    {
      return (<div>{`Relax, the week hasn't started`}</div>)
    }

    return (
      <div style={{userSelect: "none"}}>
        {totalIcesText}
        <div>{iceCube.repeat(totalIces)}</div>
        <div className="ice-cards-container">
          {filteredIceData?.map(i => {
            const team = teamInfo?.find(ti => ti.rosterID === i.rosterID);
    
            return (
              <IceCard
                key={i.rosterID}
                teamName={team?.teamName}
                displayName={team?.displayName}
                iceCount={i.iceCount}
                avatarPath={team?.avatarPath}
                shotgun={shotguns.includes(i.rosterID)}
              />
            );
          })}
        </div>
      </div>

    );
  };
  

  const IceCard: React.FC<{ teamName?: string; displayName?: string; iceCount: number; avatarPath?: string; shotgun?: boolean }> = ({
    teamName,
    displayName,
    iceCount,
    avatarPath,
    shotgun
  }) => {
    return (
    <div className="ice-card">
      {avatarPath && <img src={avatarPath} className="team-avatar" />}
      <div className="ice-card-content">
        <div>{teamName}</div>
        <div>({displayName})</div>
        <div>
          {Array.from({ length: iceCount }, (_, idx) => (
            <span key={idx}>{iceCube}</span>
            
          ))}
          {shotgun ? beer : ""}
        </div>
      </div>
    </div>

  );
}

  const handlePreviousWeek = () => {
    if (weekOffset === leagueState?.currentWeek)
    {
      return;
    }
    const offetString = (weekOffset + 1).toString()

    setWeekOffset(weekOffset + 1)

    localStorage.setItem(weekOffsetKey, offetString);

  };

  const handleNextWeek = () => {
    if (weekOffset === 0)
    {
      return;
    }
    const offetString = (weekOffset - 1).toString()
    localStorage.setItem(weekOffsetKey, offetString);

    setWeekOffset(weekOffset - 1)
  };

  const resetOffset = () => {
    const offsetString = "0"
    localStorage.setItem(weekOffsetKey, offsetString);

    setWeekOffset(0);
  }
  
  return (
    <div className="App">
      <header className="App-header">
        {iceCube} ICE WATCH {iceCube}
      </header>
      <main className="App-main">
      <div className="week-navigation">
        <button style ={{visibility: leagueState?.week === 1 ? 'hidden' : 'visible'}} onClick={handlePreviousWeek}>{`<<`}</button>
        <div className="week-text" onClick={resetOffset}>
          WEEK {leagueState?.week}, {leagueState?.year}
        </div>
        <button style ={{visibility: leagueState?.currentWeek === leagueState?.week ? 'hidden' : 'visible'}} onClick={handleNextWeek}>{`>>`}</button>
      </div>
        <DisplayIces />

      </main>
      <div className='footer'>
        <a className='linkText' href="https://www.google.com/maps/search/?api=1&query=smirnoff+ice+near+me" target="_blank" rel="noopener noreferrer">
          Need an Ice?
        </a>
      </div>
    </div>
  );
  
  
  
}

export default App;
