import axios from "axios";
import { NFLState, MatchupData, TeamInfo } from "./App";
import { stat } from "fs";

const TESSERACT_API_URL = `https://tesseract-api.com/v1/general/proxy?TesseractAPI=Sleeper&url=`;

const NFL_STATE_API_URL = 'https://api.sleeper.app/v1/state/nfl';

const ROSTERS_API_URL = (leagueID: string) => {
    return `https://api.sleeper.app/v1/league/${leagueID}/rosters`
}

const MATCHUPS_API_URL = (leagueID: string, week: number) => {
    return `https://api.sleeper.app/v1/league/${leagueID}/matchups/${week}`;
}

const TEAMS_API_URL = (leagueID: string) => {
    return `https://api.sleeper.app/v1/league/${leagueID}/users`;
}

const PLAYER_STATS_API_URL = (seasonType: string, year: number, week: number) => {
    return `https://api.sleeper.app/v1/stats/nfl/${seasonType}/${year}/${week}`;
}

const userID = "1002599543956938752"


export const getNFLState = async (weekOffset: number): Promise<NFLState | undefined> => {
    try {
        const theURL = `${TESSERACT_API_URL}${NFL_STATE_API_URL}`;
        // console.log(theURL);
        const response = await axios.get(theURL); // Use axios.get for clarity

        const { season_type, season, week } = response.data;
        if (weekOffset < 0)
        {
            weekOffset = 0;
        }
        const newState: NFLState = { season_type, year: season, week: week - weekOffset, currentWeek: week };

        return newState;
    } catch (error: any) {
        console.error('Error fetching NFL State:', error);
        return undefined; // Explicitly return undefined on error
    }
};

export const getMatchupData = async (leagueID: string, week: number): Promise<any | undefined> => {
    try {
        const theURL = `${TESSERACT_API_URL}${MATCHUPS_API_URL(leagueID, week)}`;
        // console.log(theURL);
        const response = await axios.get(theURL); // Use axios.get for clarity

        const matchupData: MatchupData[] = response.data;
        return matchupData;

    } catch (error: any) {
        console.error('Error fetching Matchup Data:', error);
        return undefined; // Explicitly return undefined on error
    }
};

export const getTeamData = async (leagueID: string): Promise<TeamInfo[] | undefined> => {
    try {
        const teamURL = `${TESSERACT_API_URL}${TEAMS_API_URL(leagueID)}`;
        // console.log(teamURL);
        const teamDataResponse = await axios.get(teamURL); // Use axios.get for clarity

        const teamData = teamDataResponse.data;

        // Extract just teamName, displayName, and avatar
        const teamInfo: TeamInfo[] = teamData.map((team: any) => ({
            teamName: team.metadata?.team_name || "Unknown", // Use default if not present
            displayName: team.display_name,
            avatarPath: team.metadata?.avatar ? team.metadata?.avatar : `https://sleepercdn.com/avatars/${team.avatar}`,
            userID: team.user_id,
            rosterID: 99999
        }));

        const rosterURL = `${TESSERACT_API_URL}${ROSTERS_API_URL(leagueID)}`;
        // console.log(rosterURL);
        const rosterResponse = await axios.get(rosterURL); // Use axios.get for clarity

        const rosterData = rosterResponse.data;


        const rosters = rosterData.map((r: any) => {
            const team = teamInfo.find(t => t.userID === r.owner_id);
            if (team)
            {
                team.rosterID = r.roster_id as number;
            }
        })

        return teamInfo;

    } catch (error: any) {
        console.error('Error fetching Team Data:', error);
        return undefined; // Explicitly return undefined on error
    }
};

export const getStats = async (seasonType: string, year: number, week: number): Promise<any | undefined> => {
    try {
        const theURL = `${TESSERACT_API_URL}${PLAYER_STATS_API_URL(seasonType, year, week)}`;
        // console.log(theURL);
        const response = await axios.get(theURL); 

        const statData = response.data;

        const playersWithData: string[] = Object.keys(statData);

        return playersWithData;

    } catch (error: any) {
        console.error('Error fetching Team Data:', error);
        return undefined; // Explicitly return undefined on error
    }
};
